import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { appAxios } from '../../api/axios';
import LoadingIndicator from '../../common/LoadingIndicator';
import StyledTabs from '../../common/StyledTabs';
import AllFreeVideos from '../../components/topics/details/lesson/AllFreeVideos';
import { sendCatchFeedback } from '../../functions/feedback';
import { PERMISSIONS } from '../../hooks/data';
import usePermissions from '../../hooks/usePermissions';
import AppLayout from '../../layout/AppLayout';
import PageHeader from '../../layout/PageLayout/PageHeader';
import { ClassType } from '../../types/data';

const tabs = ['Free Resources'];

const FreeVideos = () => {
  const [data, setData] = useState<ClassType>();
  const [loading, setLoading] = useState(true);
  const { classLevel } = useParams();
  const { hasPermission } = usePermissions();

  const getData = async () => {
    try {
      setLoading(true);

      const response = await appAxios.get(`/classes?name=${classLevel}`);
      setData(response.data?.data[0]);
    } catch (error) {
      sendCatchFeedback(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    hasPermission(PERMISSIONS.create_free_video) && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission]);

  const panels = [
    <AllFreeVideos
      key='Free-Learning-Resources'
      classLevel={data?.name || ''}
    />    
  ];

  return (
    <AppLayout>
      <PageHeader
        pageTitle={loading ? '' : `${data?.name || 'Class'} Free Learning Resources`}
        destination={`/classes/`}
        description='Manage all free learning resources associated with this class level.'
        summaryText='View, edit, and delete free learning resources'
        loading={loading}
        showBack
      />
      <section className='bg-white pb-8'>
        {loading ? (
          <div className='p-5'>
            <LoadingIndicator text='Fetching Info' />
          </div>
        ) : (
          <StyledTabs tabs={tabs} panels={panels} panelClassName='px-5' />
        )}
      </section>
    </AppLayout>
  );
};

export default FreeVideos;

import /*React,*/ { useEffect, useState } from 'react';
import PageHeader from '../../../../layout/PageLayout/PageHeader';
import Button from '../../../../common/Button';
import { AddIcon } from '../../../icons';
import AddFreeResource from './AddFreeResource';
import { appAxios } from '../../../../api/axios';
import {FreeVideoType } from '../../../../types/data';
import { sendCatchFeedback } from '../../../../functions/feedback';
import Table from '../../../../common/Table';
import usePermissions from '../../../../hooks/usePermissions';
import { PERMISSIONS } from '../../../../hooks/data';
import Pagination from '../../../../common/Pagination';
import LessonVideo from './LessonVideo';
import DeleteModal from './DeleteModal';

const AllFreeVideos = ({classLevel}: {
  classLevel: string;
}) => {
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState<FreeVideoType[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState<FreeVideoType | undefined>(undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const { hasPermission } = usePermissions();
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await appAxios.post(`/learning/free-videos`, {
        populate: {
          path: 'created_by',
          select: 'title classLevel firstName lastName video_url video_identifier',
        },
        page,
        classLevel
      });
      setData(response.data?.data);
      setTotalResults(response.data?.count);
    } catch (error) {
      sendCatchFeedback(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    hasPermission(PERMISSIONS.view_free_videos) && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission, page]);

  useEffect(() => {
    if (selected) {
      const el = document.getElementById('selected-free-video');
      if (el) {
        el.scrollIntoView(false);
      }
    }
  }, [selected]);

  const tableHeaders = ['title', 'classLevel', 'tableAction'];

  return (
    <>
      <PageHeader
        pageTitle='Free Resources'
        pageActions={
          hasPermission(PERMISSIONS.create_free_video) && (
            <Button onClick={() => setAddModal(true)}>
              <AddIcon />
              Add New Resource
            </Button>
          )
        }
      />

      <Table
        tableHeaders={tableHeaders}
        data={data || []}
        loading={loading}
        bodyStyle={{
          textAlign: 'left',
        }}
        headerStyle={{
          textAlign: 'left',
        }}
        menuItems={[
          {
            label: 'Watch Video',
            onClick: (data: FreeVideoType) => {
              setSelected((existingData) => existingData?._id === data?._id ? undefined : data);
            },
            permission: hasPermission(PERMISSIONS.view_free_video),
          },
          {
            label: 'Edit Resource',
            onClick: (data) => {
              setSelected((existingData) => existingData?._id === data?._id ? undefined : data);
              setEditModal(true);
            },
            permission: hasPermission(PERMISSIONS.update_free_video),
          },
          {
            label: 'Delete Resource',
            onClick: (data) => {
              setSelected(data);
              setDeleteModal(true);
            },
            style: {
              color: 'var(--error)',
            },
            permission: hasPermission(PERMISSIONS.delete_free_video),
          },
        ]}
      />
      <Pagination page={page} setPage={setPage} totalResults={totalResults} />

      {
        <AddFreeResource
          open={addModal}
           closeModal={() => setAddModal(false)}
          reload={getData}
          data={classLevel}
        />
      }
      
      { 
        selected ?
        <div className='mt-6' id="selected-free-video" key={selected?._id}>
          <LessonVideo
            data={selected}
            refetch={getData}
            category='free'
          />
        </div> 
         : null
      }
      
      {
        <AddFreeResource
          open={editModal}
          closeModal={() => {
            setEditModal(false)
            setSelected(undefined)
            }
          }
          reload={getData}
          data={classLevel}
          selected={selected}
        />
      }
      
      {  <DeleteModal
          open={deleteModal}
          closeModal={
            () => {
              setDeleteModal(false)
              setSelected(undefined)
            }
          }
          data={selected}
          refetch={getData}
          category='free'
        />
      }
    </>
  );
};

export default AllFreeVideos;

import { FormEvent, useState } from 'react';
import CustomModal from '../../../../common/CustomModal/CustomModal';
import Button from '../../../../common/Button';
import { appAxios } from '../../../../api/axios';
import { sendCatchFeedback, sendFeedback } from '../../../../functions/feedback';
import { SingleLessonType, FreeVideoType } from '../../../../types/data';
import { CrossIcon } from '../../../icons';

interface Props {
  closeModal: () => void;
  open: boolean;
  refetch: () => void;
  data: SingleLessonType | FreeVideoType | undefined;
  category?: string
}

const deletePaidVideoEvents = [
  'Delete this lesson from its parent lesson',
  'Erase all the associated audio, video and document files',
  'Clear all associated statistics',
];

const deleteFreeVideoEvents = [
  'Delete this video from the list of free resources.',
  'Students willl no longer be able to access this resource',
  'Clear all associated data from the database, such as the video title.',
];

function DeleteModal({ closeModal, refetch, open, data, category = 'paid' }: Props) {
  const [loading, setLoading] = useState(false);

  const submitValues = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      let response;
      
      if (category === 'paid') {
        response = await appAxios.delete('/lessons/' + data?._id);
      } else if (category === 'free') {
        response = await appAxios.delete('/lessons/free/' + data?._id);
      }

      sendFeedback(response.data?.message, 'success');
      refetch();
      return closeModal();
    } catch (error: any) {
      sendCatchFeedback(error);
    } finally {
      setLoading(false);
    }
  };

  if (!data) return null;
  return (
    <CustomModal isOpen={open} onRequestClose={closeModal} title='Delete Lesson'>
      <form onSubmit={submitValues} className='w-full'>
        <div className='w-full border-[0.6px] rounded-md border-[#DBDBDB] p-4 mt-7 mb-10'>
          <h3 className='text-[#06102B] font-semibold text-lg mb-4 text-center'>
            Deleting this lesson would do the following:
          </h3>
          <ul className='flex flex-col gap-3'>
            {category === 'paid' ? deletePaidVideoEvents.map((item) => (
              <li className='flex items-center gap-[10px] text-[#4B5768]' key={item}>
                <CrossIcon />
                <span>{item}</span>
              </li>
            )) : null}

          {category === 'free' ? deleteFreeVideoEvents.map((item) => (
              <li className='flex items-center gap-[10px] text-[#4B5768]' key={item}>
                <CrossIcon />
                <span>{item}</span>
              </li>
            )) : null}
          </ul>
        </div>

        <div className='flex items-center w-full justify-around gap-4 px-5'>
          <Button type='submit' loading={loading} className='!w-full !bg-error'>
            Delete
          </Button>
          <Button color='secondary' onClick={closeModal} className='!w-full'>
            Cancel
          </Button>
        </div>
      </form>
    </CustomModal>
  );
}

export default DeleteModal;

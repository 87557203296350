import FreeVideos from '../../pages/topics/freeVideos';
import { PrivateRoute } from '../utils';

const freeVideoRoutes = [
  {
    path: '/classes/:classLevel/free-learning-resources/',
    element: (
      <PrivateRoute>
        <FreeVideos />
      </PrivateRoute>
    ),
  },
];

export default freeVideoRoutes;
